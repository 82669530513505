export type IAdminRoles = [
  'super_finance_admin',
  'super_admin',
  'account_manager',
  'finance_admin',
  'distribution_point_admin',
  'back_office_admin',
  'customer_admin',
  'customer_service_admin'
]

export type TClaim = 'default-role' | 'admin-id' | 'organization-id' | 'employee-id'

export interface IClaims {
  'x-hasura-allowed-roles': IAdminRoles
  'x-hasura-default-role': string
  'x-hasura-admin-id': number
  'x-hasura-organization-id': number | null
  'x-hasura-employee-id': number | null
}

export interface IJwt {
  iat: number
  iss: string
  aud: string
  exp: number
  'https://hasura.io/jwt/claims': IClaims
  sso?: ISSOConfig
}

export interface ISSOConfig {
  host: string
  logoutUrl: string
}

const CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims'

const getDataFromJWT = (): IJwt | null => {
  const jwt = window.localStorage.getItem('accessToken')
  if (!jwt) return null

  const [_header, encodedData, _signature] = jwt.split('.')
  const data = JSON.parse(atob(encodedData))
  const claims = data[CLAIMS_NAMESPACE]

  const ints = ['admin-id', 'organization-id', 'employee-id']
  ints.forEach((key) => {
    claims[`x-hasura-${key}`] = parseInt(claims[`x-hasura-${key}`], 10) || null
  })

  return data as IJwt
}

export const storageAccessTokenKey = 'accessToken'

export const getClaims = (): IClaims | null => {
  const data = getDataFromJWT()
  if (!data) return null

  return data[CLAIMS_NAMESPACE] as IClaims
}

export const getClaim = (claim: TClaim): unknown => {
  const claims = getClaims()
  if (!claims) return null

  return claims[`x-hasura-${claim}`]
}

export const getSSOConfig = (): ISSOConfig | null => {
  const data = getDataFromJWT()
  if (!data) return null

  return data.sso as ISSOConfig
}

export const isImposter = (): boolean => {
  return getClaim('employee-id') !== null && getClaim('admin-id') != null
}

export const getToken = (): string | null => {
  return localStorage.getItem(storageAccessTokenKey) || sessionStorage.getItem(storageAccessTokenKey)
}

// Since we don't know if the accessToken is stored in the localStorage or the sessionStorage we clear it from both to be sure
export const flushAccessToken = (): void => {
  localStorage.removeItem(storageAccessTokenKey)
  sessionStorage.removeItem(storageAccessTokenKey)
}
